import { Col, Row } from "antd";
import * as React from "react";
import CustomList from "Views/common/custom-list/CustomList";
import styles from "./Career.module.scss";
type Props = {
  openPositions: any;
};

const OpenPosition = (props: Props) => {
  const data: any[] = [];

  props.openPositions?.map((item: any) => {
    !item?.jobTitle?.includes('(closed)') &&  data.push(item);
  });

  return (
    <div className={styles["open-position-section"]}>
      <div className="container-xl">
        <div className="text-center">
          <h2 className={styles["styled-title"]}>Open Positions</h2>
        </div>
        <div className={styles["jobs-div"]}>
          <Row justify="center">
            <Col xs={24} sm={20} md={15}>
              {
                data.length > 0 ?  <CustomList data={data} /> : 
                <h3>Currently, we do not have any open positions available.</h3>
              }
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OpenPosition;
