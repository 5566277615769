import { DatePicker } from "antd";
import React from "react";
import "./CustomDatePicker.module.scss";
type Props = {
 id?: any;
 onChange?: any;
 rest?: any;
 name?: any;
 value?: string;
 placeholder: string;
};

const CustomDatePicker = (props: Props) => {
 return (
  <DatePicker
   placeholder={props.placeholder}
   name={props.name}
   id={props.id}
   className='custom-input'
   onChange={props.onChange}
  />
 );
};
export default CustomDatePicker;
