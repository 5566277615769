import * as React from "react";
import { Col, Row, Space } from "antd";
import styles from "./AboutUs.module.scss";
import { Link } from "react-router-dom";
import readMore from "assets/images/readMore-arrow.svg";
type Props = {
 workTitle?: string;
 workDescription?: string;
 workImage: any;
};

const WorkSection = (props: Props) => {
 return (
  <div className={styles["work-section"]}>
   <div className='container-xl'>
    <div className='text-center'>
     <h2 className={styles["styled-title"]}>Work</h2>
    </div>
    <div className={styles["work-div"]}>
     <Row justify='space-around' align='middle' gutter={[24, 32]}>
      <Col xs={24} sm={6}>
       <img
        className='img-fluid'
        src={props.workImage?.data?.attributes?.url}
        alt=''
       />
      </Col>
      <Col xs={24} sm={10}>
       <h2 className={styles["recent-project"]}>{props.workTitle}</h2>
       <p className={styles["project-text"]}>{props.workDescription}</p>
       <Space>
        <div className={styles["read-more"]}></div>
        <Link to='' className={styles["read-more-link"]}>
         <Space style={{ width: "max-content" }}>
          <span>View More</span>
          <img src={readMore} alt='Read More' />
         </Space>
        </Link>
       </Space>
      </Col>
     </Row>
    </div>
   </div>
  </div>
 );
};

export default WorkSection;
