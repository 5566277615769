import * as React from "react";
import { Row, Col, Space } from "antd";
import styles from "./Home.module.scss";
import goal from "assets/images/goal-img.png";
import readMore from "assets/images/readMore-arrow.svg";
import rightRock from "assets/images/right-rock.png";
import { Link } from "react-router-dom";
type Props = {
 homePageGoalTitle: string;
 homePageGoalDescription: any;
 homePageGoalImage: any;
};
const OurGoalSection = (props: Props) => {
 return (
  <div className={styles["our-goal-section"]}>
   <div id={styles["right-rock"]}>
    <img src={rightRock} alt='' />
   </div>
   <div className='container-xl'>
    <h2 className={styles["our-goal"]}>OUR GOAL</h2>
    <div className={styles["goal-data"]}>
     <Row justify='space-around' align='middle' gutter={[24, 32]}>
      <Col xs={24} sm={12} md={10}>
       <img
        src={props.homePageGoalImage}
        alt=''
        className={styles["goal-img"]}
       />
      </Col>
      <Col xs={24} sm={12} md={10}>
       <h2 className={styles["organization-title"]}>
        {props.homePageGoalTitle}
       </h2>
       <p className={styles["description-text"]}>
        {props.homePageGoalDescription}
       </p>
       <Space>
        <div className={styles["read-more"]}></div>
        <Link to='' className={styles["read-more-link"]}>
         <Space style={{ width: "max-content" }}>
          <span>View More</span>
          <img src={readMore} alt='Read More' />
         </Space>
        </Link>
       </Space>
      </Col>
     </Row>
    </div>
   </div>
  </div>
 );
};

export default OurGoalSection;
