import { Input } from "antd";
import React from "react";
import styles from "./CustomFilePicker.module.scss";
type Props = {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  rest?: any;
  id:string
};

const CustomFilePicker = (props: Props) => {
  return (
    <div className={styles["file-wrapper"]}>
      <Input
        // {...props.rest}
        type="file"
id={props.id}
        name={props.name}
        onChange={props.handleChange}
        className={styles["file-picker"]}
      />
    </div>
  );
};

export default CustomFilePicker;
