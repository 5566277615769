import React from "react";
import CustomButton from "Views/common/custom-button/CustomButton";
import styles from "./Apply.module.scss";

type Props = {
  jobTitle?: string;
};

const UiUxDesignerSection = (props: Props) => {
  return (
    <>
      <div className={styles["lower-footer-bar"]}></div>
      <div className={styles["apply-now-bar"]}>
        <div className="container-xl">
          <div className={styles["flex-div"]}>
            <div>
              <h1>Applying for {props.jobTitle}</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UiUxDesignerSection;
