import { Row, Col, Space } from "antd";
import * as React from "react";
import styles from "./Home.module.scss";
import logo from "assets/images/IDevSol-logo.svg";
import { Link } from "react-router-dom";
import fb from "assets/images/fb.svg";
import twitter from "assets/images/twitter.svg";
import linkedIn from "assets/images/linkdeIn.svg";
import insta from "assets/images/instagram.svg";
import { useFooterSectionQuery } from "generated/graphql";
interface FooterSectionProps {}

const FooterSection: React.FunctionComponent<FooterSectionProps> = (props) => {
 const { data } = useFooterSectionQuery();

 return (
  <div className={styles["footer-section"]}>
   <div className='container-xl'>
    <Row justify='space-between' gutter={[12, 24]}>
     <Col sm={6}>
      <h2 className={styles["footer-title"]}>Find Us</h2>
      <a
       href='https://www.google.com/maps/place/Kickstart+%7C+Flagship+-+Gulberg+(62-+C2)/@31.0255239,72.2949118,8z/data=!4m19!1m13!4m12!1m4!2m2!1d72.47972!2d30.495725!4e1!1m6!1m2!1s0x3919056dad6e533d:0x6a72c370e71f26f1!2skickstart+flagship+-+gulberg+(62-+c2)!2m2!1d74.3477625!2d31.5114675!3m4!1s0x3919056dad6e533d:0x6a72c370e71f26f1!8m2!3d31.5114675!4d74.3477625'
       target='_blank'
       rel='noreferrer'
      >
       <p className={styles["company-address"]}>
        {data?.footer?.data?.attributes?.footerDescription}
       </p>
      </a>
     </Col>
     <Col sm={4}>
      <h2 className={styles["footer-title"]}>Explore</h2>
      <Space direction='vertical'>
       <Link to='/' className={styles["footer-link"]}>
        Home
       </Link>
       <Link to='/about-us' className={styles["footer-link"]}>
        About Us
       </Link>
       <Link to='/contact-us' className={styles["footer-link"]}>
        Contact
       </Link>
       <Link to='/career' className={styles["footer-link"]}>
        Career
       </Link>
       <Link to='/contact-us' className={styles["footer-link"]}>
        Sitemap
       </Link>
      </Space>
     </Col>
     <Col sm={4}>
      <h2 className={styles["footer-title"]}>Support</h2>
      <Space direction='vertical'>
       <Link to='/' className={styles["footer-link"]}>
        Register
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        Advice
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        Videos
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        Blog
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        Services
       </Link>
      </Space>
     </Col>
     <Col sm={4}>
      <h2 className={styles["footer-title"]}>Company</h2>
      <Space direction='vertical'>
       <Link to='/career' className={styles["footer-link"]}>
        Career
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        For Partners
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        Terms
       </Link>
       <Link to='/' className={styles["footer-link"]}>
        Policy
       </Link>
       <Link to='/contact-us' className={styles["footer-link"]}>
        Contact Us
       </Link>
      </Space>
     </Col>
     <Col>
      <Link to='/'>
       <img src={logo} alt='' />
      </Link>
      <div>
       <p className={styles["company-slug"]}>We’re Solution</p>
       <h3 className={styles["follow-us"]}>Follow Us on</h3>
       <Space>
        <a
         href='https://www.linkedin.com/company/idevsol/'
         target='_blank'
         rel='noreferrer'
        >
         <img src={fb} alt='FaceBook' />
        </a>
        <a href='https://twitter.com/idevsol' target='_blank' rel='noreferrer'>
         <img src={twitter} alt='Twitter' />
        </a>
        <a
         href='https://www.linkedin.com/company/idevsol/'
         target='_blank'
         rel='noreferrer'
        >
         <img src={linkedIn} alt='LinkedIn' />
        </a>
        <a
         href='https://instagram.com/idevsol'
         target='_blank'
         rel='noreferrer'
        >
         <img src={insta} alt='LinkedIn' />
        </a>
       </Space>
      </div>
     </Col>
    </Row>
   </div>
  </div>
 );
};

export default FooterSection;
