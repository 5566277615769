import { Col, Input, Row } from "antd";
import * as React from "react";
import CustomButton from "Views/common/custom-button/CustomButton";
import CustomInput from "Views/common/custom-input/CustomInput";
import styles from "./ContactUs.module.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { useContactUsFormMutation } from "generated/graphql";
import { customNotification } from "../custom-notification/CustomNotification";
interface GetInTouchSectionProps {}

const GetInTouchSection: React.FunctionComponent<GetInTouchSectionProps> = (
 props
) => {
 const { TextArea } = Input;
 const [contactUsFormFun, { isLoading }] = useContactUsFormMutation();
 return (
  <Formik
   initialValues={{ firstName: "", lastName: "", email: "", message: "" }}
   validationSchema={Yup.object({
    firstName: Yup.string().required("Please enter first name"),
    // lastName: Yup.string().required("Required"),
    email: Yup.string()
     .email("Invalid email address")
     .required("Please enter email address"),
    message: Yup.string().required("Please enter your message"),
   })}
   onSubmit={async (values, { setSubmitting, resetForm }) => {
    try {
     const res = await contactUsFormFun(values).unwrap();
     customNotification("success", "Message sent successfully...");
     resetForm({});
    } catch (error) {
     console.log("error", error);
     customNotification("error", "Something went wrong...");
    }
   }}
  >
   {(formik) => (
    <div className={styles["get-touch"]}>
     <div className='container-xl'>
      <Row justify='center'>
       <Col xs={24} sm={16} md={14}>
        <div className='text-center'>
         <h2 className={styles["styled-title"]}>Get In Touch With us</h2>
        </div>
        <div className={styles["form-div"]}>
         <form onSubmit={formik.handleSubmit}>
          <div className={styles["form-control"]}>
           <Row gutter={24}>
            <Col xs={24} sm={12}>
             <label className='custom-label' htmlFor='firstName'>
              First Name
             </label>
             <CustomInput
              id='firstName'
              type='text'
              rest={{ ...formik.getFieldProps("firstName") }}
             />
             {formik.touched.firstName && formik.errors.firstName ? (
              <div className={styles["error-msg"]}>
               {formik.errors.firstName}
              </div>
             ) : null}
            </Col>
            <Col xs={24} sm={12}>
             <label className='custom-label' htmlFor='lastName'>
              Last Name
             </label>
             <CustomInput
              id='lastName'
              type='text'
              rest={{ ...formik.getFieldProps("lastName") }}
             />
             {formik.touched.lastName && formik.errors.lastName ? (
              <div className={styles["error-msg"]}>
               {formik.errors.lastName}
              </div>
             ) : null}
            </Col>
           </Row>
          </div>
          <div className={styles["form-control"]}>
           <label className='custom-label' htmlFor='email'>
            Email
           </label>
           <CustomInput
            id='email'
            type='email'
            rest={{ ...formik.getFieldProps("email") }}
           />
           {formik.touched.email && formik.errors.email ? (
            <div className={styles["error-msg"]}>{formik.errors.email}</div>
           ) : null}
          </div>
          <div className={styles["form-control"]}>
           <label className='custom-label' htmlFor='message'>
            Message
           </label>
           <TextArea
            id='message'
            className='custom-textArea'
            rows={6}
            {...formik.getFieldProps("message")}
           />
           {formik.touched.message && formik.errors.message ? (
            <div className={styles["error-msg"]}>{formik.errors.message}</div>
           ) : null}
          </div>
          <div className={styles["btn-div"]}>
           <CustomButton
            loading={isLoading}
            className='header-contact-us-btn'
            htmlType='submit'
           >
            Get Started
           </CustomButton>
          </div>
         </form>
        </div>
       </Col>
      </Row>
     </div>
    </div>
   )}
  </Formik>
 );
};

export default GetInTouchSection;
