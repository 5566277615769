import { List } from "antd";
import * as React from "react";
import "./CustomList.module.scss";
import CustomButton from "../custom-button/CustomButton";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
interface CustomListProps {
 data: {
  jobTitle: string;
  jobLink: string;
  jobDescription: string;
  id: string;
 }[];
}

const CustomList: React.FunctionComponent<CustomListProps> = (props) => {
 const navigate = useNavigate();
 return (
  <List
   itemLayout='horizontal'
   dataSource={props.data}
   renderItem={(item) => {
    return (
     <List.Item>
      <List.Item.Meta
       title={<span className='job-title'>{item.jobTitle}</span>}
       description={
        <span className='job-description'>
         Lahore / Full Time / Development
        </span>
       }
      />
      <CustomButton className='apply-btn'>
       <span onClick={() => navigate(`/job-details?${item?.id}`)}>
        Apply Now
       </span>
      </CustomButton>
     </List.Item>
    );
   }}
  />
 );
};

export default CustomList;
