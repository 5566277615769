import { Row, Col, Space } from "antd";
import * as React from "react";
import styles from "./Service.module.scss";
import rightArrow from "assets/images/right-arrow.png";
import arrowLeft from "assets/images/left-arrow.png";
type Props = {
  bestDescription?: string;
  blogs?: any;
};

const WhatDoSection = (props: Props) => {
  return (
    <>
      <div className={styles["do-best-section"]}>
        <div className={styles["arrow-left"]}>
          <img src={arrowLeft} alt="" />
        </div>
        <div className="container-xl">
          <Row justify="center">
            <Col xs={24} sm={15} md={12} style={{ marginTop: "20px" }}>
              <div className="text-center">
                <h2 className={styles["styled-title"]}>What we do Best</h2>
              </div>
              <p className={styles["best-text"]}>{props.bestDescription}</p>
            </Col>
          </Row>
          <div className={styles["developmet"]}>
            <Row justify="space-around" align="middle" gutter={[24, 32]}>
              <Col xs={24} sm={8}>
                <img
                  className="img-fluid"
                  src={props.blogs[0]?.image?.data?.attributes?.url}
                  alt=""
                />
              </Col>
              <Col xs={24} sm={8}>
                <h2 className={styles["service-title"]}>
                  {props.blogs[0]?.title}
                </h2>
                <p className={styles["service-text"]}>
                  {props.blogs[0]?.description}
                </p>
                <Space direction="vertical">
                  {props.blogs[0]?.points?.map((item: any, index: number) => (
                    <Space direction="horizontal" key={index}>
                      <div className="service"></div>
                      <span className={styles["service-text"]}>
                        {item?.point}
                      </span>
                    </Space>
                  ))}
                </Space>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles["right-arrow"]}>
          <img src={rightArrow} alt="" />
        </div>
      </div>
      <div className={styles["design-service-section"]}>
        <div className="container-xl">
          <Row justify="space-around" align="middle" gutter={[24, 32]}>
            <Col xs={{ span: 24, order: 2 }} sm={{ span: 8, order: 1 }}>
              <h2 className={styles["service-title"]}>
                {props.blogs[1]?.title}
              </h2>
              <p className={styles["service-text"]}>
                {props.blogs[1]?.description}
              </p>
              <Space direction="vertical">
                {props.blogs[0]?.points?.map((item: any, index: number) => (
                  <Space direction="horizontal" key={index}>
                    <div className="service"></div>
                    <span className={styles["service-text"]}>
                      {item?.point}
                    </span>
                  </Space>
                ))}
              </Space>
            </Col>
            <Col xs={{ span: 24, order: 1 }} sm={{ span: 8, order: 2 }}>
              <img
                className="img-fluid"
                src={props.blogs[1]?.image?.data?.attributes?.url}
                alt=""
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles["Devops-serice-section"]}>
        <div className={styles["arrow-left"]}>
          <img src={arrowLeft} alt="" />
        </div>
        <div className="container-xl">
          <Row justify="space-around" align="middle" gutter={[24, 32]}>
            <Col xs={24} sm={8}>
              <img
                className="img-fluid"
                src={props.blogs[2]?.image?.data?.attributes?.url}
                alt=""
              />
            </Col>
            <Col xs={24} sm={8}>
              <h2 className={styles["service-title"]}>
                {props.blogs[2]?.title}
              </h2>
              <p className={styles["service-text"]}>
                {props.blogs[2]?.description}
              </p>
              <Space direction="vertical">
                {props.blogs[2]?.points?.map((item: any, index: number) => (
                  <Space direction="horizontal" key={index}>
                    <div className="service"></div>
                    <span className={styles["service-text"]}>
                      {item?.point}
                    </span>
                  </Space>
                ))}
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default WhatDoSection;
