import React, { useState } from "react";
import { Drawer, Menu, Space } from "antd";
import { Header } from "antd/lib/layout/layout";
import styles from "./Header.module.scss";
import logo from "assets/images/IDevSol-logo.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "Views/common/custom-button/CustomButton";
import Icon from "@ant-design/icons";
import { MenuSvg } from "./../../common/Icons/MenuSvg";
interface IAppProps {
 color: string;
 id?: string;
}
const App: React.FunctionComponent<IAppProps> = (props) => {
 const location = useLocation();
 const navigate = useNavigate();
 const [visible, setVisible] = useState(false);
 const [width, setWidth] = useState(window.innerWidth);
 window.addEventListener("resize", () => {
  setWidth(window.innerWidth);
 });
 const showDrawer = () => {
  setVisible(true);
 };
 const onClose = () => {
  setVisible(false);
 };
 const drawer = () => {
  return (
   <div className={styles["drawer"]}>
    <CustomButton type='link' className='menu-btn' onClick={showDrawer}>
     <Icon component={MenuSvg} />
    </CustomButton>
   </div>
  );
 };
 const navMenu = () => {
  return (
   <ul className='IDevSol-navBar'>
    <li>
     <NavLink to='/'>Home</NavLink>
    </li>
    <li>
     <NavLink to='/service'>Service</NavLink>
    </li>
    <li>
     <NavLink to='/about-us'>About us</NavLink>
    </li>
    <li>
     <NavLink to='/career'>Career</NavLink>
    </li>
    <li>
     <CustomButton
      onClick={() => navigate("/contact-us")}
      className='header-contact-us-btn'
     >
      Contact Us
     </CustomButton>
    </li>
   </ul>
  );
 };
 const menu = () => {
  return (
   <Menu
    className='IDevSol-navBar'
    theme='light'
    mode='horizontal'
    defaultSelectedKeys={[location.pathname]}
   >
    <Menu.Item key='/'>
     <Link className='default' to='/'>
      Home
     </Link>
    </Menu.Item>
    <Menu.Item key='/service'>
     <Link to='/service'>Services</Link>
    </Menu.Item>
    <Menu.Item key='/about-us'>
     <Link to='/about-us'>About us</Link>
    </Menu.Item>
    <Menu.Item key='/career'>
     <Link to='/career'>Career</Link>
    </Menu.Item>
    <Menu.Item className='header-contact-us-item' key='5'>
     <CustomButton
      onClick={() => navigate("/contact-us")}
      className='header-contact-us-btn'
     >
      Contact Us
     </CustomButton>
    </Menu.Item>
   </Menu>
  );
 };
 return (
  <>
   <div className={styles["bg-white"]}>
    <Header
     id={props.id}
     className='IDevsol-header'
     style={{ backgroundColor: props.color }}
    >
     <div className='container-xl'>
      <div className='IDevSol-logo'>
       <Link to='/'>
        <img src={logo} alt='IDevSol' />
       </Link>
      </div>
      {width < 900 ? drawer() : navMenu()}
     </div>
    </Header>
   </div>
   <Drawer
    className='menu-drawer'
    placement='left'
    onClose={onClose}
    visible={visible}
    width={300}
   >
    <Space direction='vertical' size={18}>
     <NavLink to='/'>Home</NavLink>
     <NavLink to='/service'>Service</NavLink>
     <NavLink to='/about-us'>About us</NavLink>
     <NavLink to='/career'>Career</NavLink>
     <CustomButton
      className='header-contact-us-menu-btn'
      onClick={() => navigate("/contact-us")}
     >
      Contact Us
     </CustomButton>
    </Space>
   </Drawer>
  </>
 );
};

export default App;
