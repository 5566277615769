import * as React from "react";
import { Input } from "antd";
import "./CustomInput.module.scss";

interface CustomInputProps {
  id: string;
  type: string;
  rest?: any;
}

const CustomInput: React.FunctionComponent<CustomInputProps> = (props) => {
  return (
    <Input
      className="custom-input"
      id={props.id}
      type={props.type}
      {...props.rest}
    />
  );
};

export default CustomInput;
