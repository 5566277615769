import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import * as React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import styles from "./JobDetails.module.scss";
import FooterSection from "../Home/FooterSection";
import SendEmailSection from "../Home/SendEmailSection";
import { useCareersPageQuery } from "generated/graphql";
import InternalServerError from "../error-pages/InternalServerError";
import CustomLoader from "./../../common/custom-loader/CustomLoader";
import UiUxDesignerSection from "./UiUxDesignerSection";
import ContentSection from "./ContentSection";
interface CareerProps {}

const JobDetails: React.FunctionComponent<CareerProps> = (props) => {
  const { data, isLoading } = useCareersPageQuery();
  const d = data?.career?.data?.attributes;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (isLoading) {
    return <CustomLoader />;
  }
  if (!data && isLoading === false) {
    return <InternalServerError />;
  }
  return (
    <div className="container-fluid">
      <Layout>
        <Header color="#fff" id="header-scrolled" />
        <Content className="IDevSol-content">
          <UiUxDesignerSection />
          <ContentSection />
          <SendEmailSection />
          <FooterSection />
        </Content>
        <Footer />
      </Layout>
    </div>
  );
};

export default JobDetails;
