import { Col, Input, Row, Space } from "antd";
import * as React from "react";
import CustomButton from "Views/common/custom-button/CustomButton";
import CustomInput from "Views/common/custom-input/CustomInput";
import styles from "./Apply.module.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { useContactUsFormMutation } from "generated/graphql";
import { customNotification } from "../custom-notification/CustomNotification";
import CustomDatePicker from "Views/common/custom-date-picker/CustomDatePicker";
import CustomFilePicker from "Views/common/custom-file-picker/CustomFilePicker";
import {
  useApplyJobFormMutation,
  useUploadFileMutation,
} from "generated/graphql";
import { useLocation } from "react-router-dom";
import form from "antd/lib/form";
import UiUxDesignerSection from "./UiUxDesignerSection";
type Props = {};

const ApplyNowForm = (props: Props) => {
  const location = useLocation();
  const jobTitle = location.search.split("?")[1].split("%20").join(" ");
  const [dobInitValue, setdobInitValue] = React.useState("");
  const [appJobFun] = useApplyJobFormMutation();
  const [uploadFileFun] = useUploadFileMutation();
  const { TextArea } = Input;
  return (
    <>
      <UiUxDesignerSection jobTitle={jobTitle} />
      <div className={styles["get-touch"]}>
        <div className="container-xl">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              dob: "",
              linkedInUrl: "",
              coverLetter: "",
              resume: "",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              phone: Yup.string().required("Required"),
              resume: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              try {
                const uploadRes = await uploadFileFun({
                  file: values.resume,
                }).unwrap();
                values.resume = uploadRes.upload.data?.id as string;
                await appJobFun({ ...values, jobTitle });
                // values.dob = "";
                resetForm({});
                customNotification("success", "Message sent successfully...");
              } catch (error) {
                console.log("error", error);
                customNotification("error", "Something went wrong...");
              }
            }}
          >
            {(formik) => (
              <Row justify="center">
                <Col xs={24} sm={16} md={14}>
                  <div className={styles["mb-3"]}>
                    <Space>
                      <div className={styles["dot"]}></div>
                      <h2 className={styles["personal-details"]}>
                        {" "}
                        Personal Details
                      </h2>
                    </Space>
                  </div>
                </Col>
                <Col xs={24} sm={16} md={14}>
                  <div className={styles["form-div"]}>
                    <form onSubmit={formik.handleSubmit}>
                      <div className={styles["form-control"]}>
                        <Row gutter={24}>
                          <Col xs={24} sm={12}>
                            <label className="custom-label" htmlFor="firstName">
                              First Name{" "}
                              <span className={styles["error-msg"]}>*</span>
                            </label>
                            <CustomInput
                              id="firstName"
                              type="text"
                              rest={{ ...formik.getFieldProps("firstName") }}
                            />
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                              <div className={styles["error-msg"]}>
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </Col>
                          <Col xs={24} sm={12}>
                            <label className="custom-label" htmlFor="lastName">
                              Last Name
                              <span className={styles["error-msg"]}>*</span>
                            </label>
                            <CustomInput
                              id="lastName"
                              type="text"
                              rest={{ ...formik.getFieldProps("lastName") }}
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                              <div className={styles["error-msg"]}>
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      <div className={styles["form-control"]}>
                        <label className="custom-label" htmlFor="email">
                          Email <span className={styles["error-msg"]}>*</span>
                        </label>
                        <CustomInput
                          id="email"
                          type="email"
                          rest={{ ...formik.getFieldProps("email") }}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className={styles["error-msg"]}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["form-control"]}>
                        <label className="custom-label" htmlFor="email">
                          Phone
                          <span className={styles["error-msg"]}>*</span>
                        </label>
                        <CustomInput
                          id="phone"
                          type="text"
                          rest={{ ...formik.getFieldProps("phone") }}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className={styles["error-msg"]}>
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["form-control"]}>
                        <label className="custom-label" htmlFor="email">
                          DOB
                        </label>
                        <CustomDatePicker
                          placeholder="Select Your Date of Birth"
                          name="dob"
                          onChange={(_date: any, dateString: string) => {
                            formik.setFieldValue("dob", dateString);
                          }}
                        />
                        {formik.touched.dob && formik.errors.dob ? (
                          <div className={styles["error-msg"]}>
                            {formik.errors.dob}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["form-control"]}>
                        <label className="custom-label" htmlFor="email">
                          LinkedIn URL
                        </label>
                        <CustomInput
                          id="linkedInUrl"
                          type="text"
                          rest={{ ...formik.getFieldProps("linkedInUrl") }}
                        />
                        {formik.touched.linkedInUrl &&
                        formik.errors.linkedInUrl ? (
                          <div className={styles["error-msg"]}>
                            {formik.errors.linkedInUrl}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["form-control"]}>
                        <label className="custom-label" htmlFor="message">
                          Cover Letter
                        </label>
                        <TextArea
                          id="coverLetter"
                          className="custom-textArea"
                          rows={6}
                          {...formik.getFieldProps("coverLetter")}
                        />
                        {formik.touched.coverLetter &&
                        formik.errors.coverLetter ? (
                          <div className={styles["error-msg"]}>
                            {formik.errors.coverLetter}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["form-control"]}>
                        <label className="custom-label" htmlFor="message">
                          Resume <span className={styles["error-msg"]}>*</span>
                        </label>
                        <CustomFilePicker
                          id="resumeFile"
                          name="resume"
                          rest={{ ...formik.getFieldProps("resume") }}
                          handleChange={async (event: any) => {
                            let url = event.target.files[0];
                            formik.setFieldValue("resume", url);
                          }}
                        />
                        {formik.touched.coverLetter &&
                        formik.errors.coverLetter ? (
                          <div className={styles["error-msg"]}>
                            {formik.errors.coverLetter}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles["btn-div"]}>
                        <CustomButton
                          className="header-contact-us-btn"
                          htmlType="submit"
                        >
                          Submit
                        </CustomButton>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ApplyNowForm;
