import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Home } from "./Views/components/Home/Home";
import Service from "./Views/components/service/Service";
import AboutUs from "./Views/components/about-us/AboutUs";
import Career from "./Views/components/career/Career";
import ContactUs from "./Views/components/contact-us/ContactUs";
import NotFound from "./Views/components/error-pages/NotFound";
import InternalServerError from "Views/components/error-pages/InternalServerError";
import JobDetails from "Views/components/job-details/JobDetails";
import Apply from "Views/components/apply/Apply";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/job-details" element={<JobDetails />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/under-maintance" element={<InternalServerError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
