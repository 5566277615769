import * as React from "react";
import { Col, Row, Space } from "antd";
import CustomButton from "Views/common/custom-button/CustomButton";
import styles from "./AboutUs.module.scss";
import { useNavigate } from "react-router-dom";
type Props = {
  heroDescription?: string;
  heroImage?: any;
};

const AboutUsSection = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className={styles["about-us-section"]}>
      <div className="container-xl">
        <Row justify="space-between" align="middle" gutter={[24, 32]}>
          <Col xs={24} sm={12} md={12}>
            <h1 className={styles["styled-title"]}>About Us</h1>
            <p className={styles["description-text"]}>
              {props.heroDescription}
            </p>
            <div className={styles["service-section-btn"]}>
              <Space size={20}>
                <CustomButton
                  className="contact-us-btn"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </CustomButton>
                <CustomButton
                  className="service-btn"
                  onClick={() => navigate("/service")}
                >
                  Our Services
                </CustomButton>
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={12} md={10}>
            <img
              className="img-fluid"
              src={props.heroImage?.data?.attributes?.url}
              alt="IDevSol Services"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUsSection;
