import React from "react";
import CustomButton from "Views/common/custom-button/CustomButton";
import styles from "./JobDetails.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useCareersPageQuery } from "generated/graphql";
type Props = {};

const UiUxDesignerSection = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.search.split("?")[1];
  const { data, isLoading } = useCareersPageQuery();
  let d = data?.career?.data?.attributes?.openPositions;
  const filteredData = d?.filter((item) => {
    return item?.id == id;
  });
  console.log("filtered Daa", filteredData);
  return (
    <>
      <div className={styles["lower-footer-bar"]}></div>
      <div className={styles["apply-now-bar"]}>
        <div className="container-xl">
          <div className={styles["flex-div"]}>
            <div>
              <h1>Job Details</h1>
              <p>idevsol</p>
            </div>

            <CustomButton
              className="header-contact-us-btn"
              onClick={() =>
                navigate(
                  `/apply?${
                    filteredData ? filteredData[0]?.jobDetailsTitle : ""
                  }`
                )
              }
            >
              Apply Now
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default UiUxDesignerSection;
