import React from "react";
import CustomButton from "Views/common/custom-button/CustomButton";
import styles from "./JobDetails.module.scss";
import { useCareersPageQuery } from "generated/graphql";
import { useLocation, useNavigate } from "react-router-dom";
type Props = {};

const ContentSection = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.search.split("?")[1];
  const { data, isLoading } = useCareersPageQuery();
  let d = data?.career?.data?.attributes?.openPositions;
  const filteredData = d?.filter((item) => {
    return item?.id == id;
  });

  return (
    <div className="container-xl">
      <div className={styles["content-container"]}>
        <h2>{filteredData ? filteredData[0]?.jobDetailsTitle : ""}</h2>
        <p>{filteredData ? filteredData[0]?.jobDetailsDescription : ""}</p>
        <h2>{filteredData ? filteredData[0]?.responsibilitiesTitle : ""}</h2>

        <ul>
          {filteredData
            ? filteredData[0]?.responsibilitiesPoints?.map((point) => {
                return <li>{point?.responsibilityPoint}</li>;
              })
            : ""}
        </ul>

        <h2>{filteredData ? filteredData[0]?.aboutYouTitle : ""}</h2>
        <ul>
          {filteredData
            ? filteredData[0]?.aboutYouPoints?.map((point) => {
                return <li>{point?.aboutYouPoint}</li>;
              })
            : ""}
        </ul>
        <h2>Are you interested to Apply Now</h2>
        <div className={styles["last-apply-now-div"]}>
          <p>Click on the button below... </p>
          <CustomButton
            className="header-contact-us-btn"
            onClick={() =>
              navigate(
                `/apply?${filteredData ? filteredData[0]?.jobDetailsTitle : ""}`
              )
            }
          >
            Apply Now
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
