import * as React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styles from "./Career.module.scss";
import gallery1 from "assets/images/gallery1.png";
import gallery2 from "assets/images/gallery2.png";
import gallery3 from "assets/images/gallery3.png";
type Props = {
  activities: any;
};

const ActivitiesSection = (props: Props) => {
  return (
    <div className={styles["activities-section"]}>
      <div className="text-center">
        <h2 className={styles["styled-title"]}>Activities</h2>
      </div>
      <div className={styles["gallery-grid"]}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}>
          <Masonry className="career-gallery" gutter={12}>
            {props.activities?.data?.map((image: any, index: number) => (
              <img
                key={index}
                src={image?.attributes?.url}
                style={{ width: "100%", display: "block" }}
                alt=""
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
};

export default ActivitiesSection;
