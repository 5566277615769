import React from "react";
import { Row, Col, Space } from "antd";
import styles from "./Home.module.scss";
import img from "assets/images/hero-section.png";
import CustomButton from "Views/common/custom-button/CustomButton";
import { useNavigate } from "react-router-dom";
type Props = {
 heroTitle: string;
 heroDescription: string;
};
export default function HeroSection(props: Props) {
 const navigate = useNavigate();
 return (
  <div className='container-xl'>
   <div className={styles["hero-section"]}>
    <Row>
     <Col xs={24} sm={12}>
      <h1 className={styles["page-title"]}>{props.heroTitle}</h1>
      <p className={styles["description-text"]}>{props.heroDescription}</p>
      <div className={styles["hero-section-btn"]}>
       <Space size={20}>
        <CustomButton
         className='contact-us-btn'
         onClick={() => navigate("/contact-us")}
        >
         Contact Us
        </CustomButton>
        <CustomButton
         className='service-btn'
         onClick={() => navigate("/service")}
        >
         Our Services
        </CustomButton>
       </Space>
      </div>
     </Col>
     <div className={styles["section-bg"]}>
      <img src={img} alt='' />
     </div>
    </Row>
   </div>
  </div>
 );
}
